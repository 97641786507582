;import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

import muriel from '../images/team/Muriel.jpg';
import odile from '../images/team/odile-montaufray.jpg';
import elsa from '../images/team/photo-elsa.png';
import sylvia from '../images/team/sylviaBalea.jpg';
import sylvie from '../images/team/sylvieeymard.jpg';

const TeamPage = () => (
  <Layout>
    <SEO
      title="Qui sommes-nous ? Cinélangues"
      description="Cinélangues est un dispositif cinématographique mis en place par des professeurs de langues et des professionnels du cinéma pour des professeurs de langues."
      pathname="/a-propos-qui-sommes-nous/"
    />
    <div className="page">
      <div className="container jumbotron">
        <div className="row">
          <div className="col-sm-12 col-sm-12 header-content my-auto">
            <h1>
              <i className="fal fa-camera-movie ml-2 mr-2"></i>Qui sommes-nous ?
            </h1>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="card card-orange col-lg-6">
            <div className="card-body">
              <h3 className="mt-0">
                Depuis sa création en 2009, Cinélangues a à cœur d’intégrer le cinéma
                dans l’enseignement des langues en proposant aux professeurs de
                langue des films d’auteur en version originale, peu diffusés ou
                difficilement accessibles sur les écrans.
              </h3>
            </div>
          </div>
          <div className="card card-illustration-2 d-none d-lg-block col-lg-6"></div>
          <div className="card card-content col-lg-8">
            <div className="card-body">
              <h4 className="mt-0">
                Tout d’abord dédié aux langues latines (espagnol, italien, portugais) dans le cadre du mythique cinéma Le Latina (Paris), devenu aujourd hui le cinéma Le Luminor, Cinélangues s’est
                développé dans plusieurs cinémas parisiens et a intégré, en 2010, l’allemand dans son dispositif.
              </h4>
              <p>
                Fenêtres ouvertes sur l’Allemagne, l’Espagne et les pays
                hispanophones, l’Italie, le Portugal et le Brésil, les films qui
                constituent la programmation scolaire Cinélangues plongent les élèves
                de lycée et de collège dans la réalité culturelle et linguistique
                diverse de ces pays. Ils permettent un vrai travail linguistique
                facilité par les documents pédagogiques proposés aux professeurs de
                langues pour chacun des films (extraits de dialogues du film,
                critiques, dossiers ou pistes pédagogiques).
              </p>
              <p>
                Plus de 400 films représentatifs du cinéma d’auteur espagnol et latino-américain, italien, allemand, portugais et brésilien ont fait l’objet d’un travail de diffusion en direction du public scolaire parisien depuis la mise en place de Cinélangues. Cette action se poursuit  chaque année avec des programmations constamment renouvelées et permet aux plus jeunes de découvrir des cinématographies peu diffusées en salles et de s’ouvrir aux cultures dont ils apprennent la langue.
              </p>
            </div>
          </div>
          <div className="card card-lead col-lg-4">
            <div className="card-body">
              <p>
                Cinélangues est soutenu depuis sa création par la Mission Cinéma de
                la Mairie de Paris. Il joue un rôle de médiation entre les
                distributeurs, les salles de cinéma, les professeurs et les
                établissements scolaires.
              </p>
              <p>
                Son objectif essentiel est de faciliter l’intégration du cinéma dans
                l’enseignement des langues en proposant une programmation spécifique
                annuelle et trimestrielle aux professeurs de langue. Privilégiant la
                découverte collective en salles, Cinélangues propose une approche
                linguistique et culturelle du cinéma. Cette programmation est
                accessible – généralement en matinée- pour les professeurs et leurs
                classes dans plusieurs cinémas parisiens.
              </p>
            </div>
          </div>
          <div className="card card-content col-sm-12 col-lg-6">
            <div className="card-body">
              <div className="row">
                <div className="col-10 px-1 my-0">
                  <h3>Odile Cleren-Montaufray</h3>
                </div>
                <div className="col-2">
                  <img
                    src={odile}
                    alt="Odile Montaufray"
                    className="w-100 rounded-circle"
                  />
                </div>
              </div>
              <p className="p-1 pr-3 pb-0">
                Depuis toujours passionnée par l’Espagne et la culture hispanique,
                Odile Montaufray a d’abord enseigné l’espagnol avant de s’ouvrir à
                d’autres langues latines dans le cadre d’une mission de plusieurs
                années au sein de l’Union latine. Elle a initié dans ce cadre, avec
                Silvia Balea, un travail de diffusion des langues latines auprès du
                public scolaire par le cinéma qui a conduit à la création de
                l’association Cinélangues. Convaincue que le cinéma est un support
                irremplaçable dans l’enseignement des langues, elle a publié
                plusieurs ouvrages dans le domaine Langues et Cinéma.
              </p>
            </div>
          </div>
          <div className="card card-content col-sm-12 col-lg-6">
            <div className="card-body">
              <div className="row">
                <div className="col-10 px-1 my-0">
                  <h3>Silvia Balea</h3>
                </div>
                <div className="col-2">
                  <img
                    src={sylvia}
                    alt="Silvia Balea"
                    className="w-100 rounded-circle"
                  />
                </div>
                <p className="p-1 pr-3 pb-0">
                  Passionnée du monde latin, Silvia Balea a consacré près de vingt
                  ans de sa vie à la direction du cinéma Le Latina, en assurant sa
                  programmation, ses animations et sa gestion. Particulièrement
                  sensible aux actions en direction du jeune public, elle a créé, en
                  étroite collaboration avec Odile Montaufray, l’association
                  Cinélangues afin d’offrir aux professeurs de langues latines un
                  outil pédagogique qui complète leur enseignement à travers le
                  cinéma. Elle a occupé le poste de Commissaire Général Adjoint pour l’Année du Mexique en France ainsi que celui d’ Attachée culturelle et audiovisuelle à l’Ambassade de France de Lisbonne.
                </p>
              </div>
            </div>
          </div>
          <div className="card card-content col-sm-12 col-lg-6">
            <div className="card-body">
              <div className="row">
                <div className="col-10 px-1 my-0">
                  <h3>Muriel Walter</h3>
                </div>
                <div className="col-2">
                  <img
                    src={muriel}
                    alt="Donatella Mendolicchio"
                    className="w-100 rounded-circle"
                  />
                </div>
                <p className="p-1 pr-3 pb-0">Arrivée à l’enseignement de l’espagnol après plusieurs années de travail en Espagne et d’études correspondantes, Muriel Walter en a gardé une passion pour la vie culturelle et le contact quotidien avec les espagnols. Cette passion s’est étendue aux pays hispaniques et c’est tout naturellement que ce monde s’est imposé à elle et est devenu un de ses principaux centres d’intérêt. C’est à l’ENC (Ecole Nationale de Commerce Paris 17ème) où elle a fait l’essentiel de sa carrière, qu’elle fait la connaissance d’Odile Montaufray qui, par sa passion, l’attire vers divers projets dont Cinélangues. Grâce à Cinélangues, nombre de ses étudiants ont eu accès au cinéma espagnol et latino-américains et, aujourd’hui, elle continue à apporter sa contribution.</p>
              </div>
            </div>
          </div>
          <div className="card card-content col-sm-12 col-lg-6">
            <div className="card-body">
              <div className="row">
                <div className="col-10 px-1 my-0">
                  <h3>Sylvie Eymard</h3>
                </div>
                <div className="col-2">
                  <img
                    src={sylvie}
                    alt="Sylvie Eymard"
                    className="w-100 rounded-circle"
                  />
                </div>
                <p className="p-1 pr-3 pb-0">
                  Professeur agrégé d’espagnol, Sylvie Eymard a vécu plusieurs années
                  à Madrid avant de s’installer à Paris. Elle enseigne actuellement
                  en Classes Préparatoires HEC au Lycée Carnot (Paris). Elle
                  collabore régulièrement avec Cinélangues depuis sa
                  rencontre en 2002, à Buenos-Aires, avec Odile Montaufray. Elle
                  contribue à la diffusion cinématographique du cinéma en espagnol
                  auprès du public scolaire grâce aux dossiers pédagogiques qu’elle
                  rédige pour les films espagnols et latino-américains.
                </p>
              </div>
            </div>
          </div>
          <div className="card card-content col-sm-12 col-lg-6">
            <div className="card-body">
              <div className="row">
                <div className="col-10 px-1 my-0">
                  <h3>Elsa Jaghouar</h3>
                </div>
                <div className="col-2">
                  <img
                    src={elsa}
                    alt="Elsa Jaghouar"
                    className="w-100 rounded-circle"
                  />
                </div>
                <p className="p-1 pr-3 pb-0">
                  Elsa Jaghouar est professeure d’allemand au Lycée Hélène Boucher
                  (Paris). Après avoir occupé un poste pendant plusieurs années à
                  l’ENC Bessières, elle enseigne actuellement au Lycée Hèlène Boucher
                  à Paris. Elle partage avec Cinélangues un grand intérêt pour le
                  cinéma et particulièrement pour le cinéma des pays germanophones et
                  la certitude qu’il apporte aux différents publics scolaires la
                  possibilité d’étendre leurs connaissances culturelles et
                  civilisationnelles sur les pays dont ils apprennent la langue et
                  par-là même d’élargir leur horizon culturel et linguistique
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export default TeamPage;
